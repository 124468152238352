<template>
  <div class="modal" role="dialog">
    <div class="admins md-layout-item">
      <div class="admins-navbar">
        <md-tabs>
          <md-tab id="TabUsersInfo" md-label="Информация о пользователе" />
        </md-tabs>
      </div>
    </div>
    <md-content class="md-scrollbar">
      <TabUsersInfo :block-added-info="true" />
    </md-content>
    <div class="buttons-wrap justify-end">
      <md-button class="md-raised md-success">Изменить</md-button>
    </div>
  </div>
</template>

<script>
import TabUsersInfo from "../Common/TabUsersInfo.vue";
export default {
  components: {
    TabUsersInfo,
  },
  data() {
    return {
      activeTab: "TabUsersInfo",
    };
  },

  methods: {
    async blockUser() {
      try {
        this.$notify({
          message: "Пользователь заблокирован",
          icon: "task_alt",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "primary",
        });
        this.$router.push("/users/");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  height: auto;
  padding: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px;
  background: #fff;
  width: 850px;
  &-content {
    padding: 20px;
    height: 350px;
    overflow-y: scroll;
  }
}
.admins-wrap {
  padding-top: 40px;
}

.admins-navbar {
  margin-bottom: 20px;

  &::v-deep {
    .md-tabs-navigation {
      background-color: #58b05c !important;
      padding: 20px;
    }
  }
}
</style>
